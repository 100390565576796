<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
    <div class="fix-box">
      <div class="ser" @click="$router.push('/service')">
        <img src="./assets/icon/service.png" />
      </div>
      <div class="gif" @click="$router.push('/gift')"  v-if="isLogin">
        <img src="./assets/icon/gif.png" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  created() {
    var lang = localStorage.getItem('lang')
    if (!lang) {
      localStorage.setItem('lang', 'en_es')
      this.$i18n.locale = 'en_es'
    }
  },
  computed: {
      ...mapGetters("login", ["isLogin"]),
  },
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  background: #f5f8ff;
}
body {
  margin: 0;
}

.fix-box {
  position: fixed;
  right: 10px;
  top: 40%;
  z-index: 99;

  .ser {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    margin-left: 5px;
    margin-bottom: 40px;

    img {
      width: 36px;
      height: 36px;
      margin-top: 7px;
    }
  }

  .gif {
    width: 60px;
    height: 60px;

    img {
      width: 60px;
      height: 60px;
    }
  }
}
</style>
