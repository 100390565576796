import Vue from 'vue'
import Vuex from 'vuex'

// 引入设备模块
import device from './modules/device' 
import login from './modules/login'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    device, // 引入设备模块
    login
  }
})
