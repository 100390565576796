import axios from 'axios';
import router from '../router'
import i18n from '../i18n/index'
import store from '../store/index';
import { Message } from 'element-ui';
import { stringify } from 'qs';

// 创建 Axios 实例
const service = axios.create({
  baseURL: 'https://api.riotplatforms-better.com/', // 基础请求地址，可根据环境变量配置
  timeout: 10000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 可以在这里添加一些统一的请求配置（例如添加 token）
    const token = localStorage.getItem('token'); // 从 localStorage 中获取 token
    const lang = localStorage.getItem('lang');
    config.headers['Language'] = lang
    if (token) {
      config.headers['Token'] = token; // 在请求头中添加 token
    }
    return config;
  },
  error => {
    // 请求错误时处理
    // console.log(error); // 打印错误日志
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data;
    // console.log('store', store.dispatch('login/checkLoginType'))
    // 判断返回的状态码
    if (res.code !== 0) {
      // 非 200 状态码时，显示错误信息
      Message({
        message: res.info || 'Error',
        type: 'error',
        duration: 2000
      });

      // 处理一些常见错误，例如 token 过期
      if (res.code === -400) {
        // 可以跳转到登录页或者做其他处理
        Message({
          message: res.info,
          type: 'error',
          duration: 2000
        });
        setTimeout(() => {
          localStorage.removeItem('token')
          store.dispatch('login/checkLoginType')
          router.push({path: '/login'})
        }, 2000);
      }

      return res;
    } else {
      return res; // 返回响应数据
    }
  },
  error => {
    // 响应错误时处理
    console.log('err' + error); // 打印错误日志
    // Message({
    //   message: i18n.t('系统错误'),
    //   type: 'error',
    //   duration: 3000
    // });
    // window.location.reload()
    return Promise.reject(error);
  }
);

export default service;
