import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/index.vue') // 首页
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/login.vue') // 登录
  },
  {
    path: '/rich',
    name: 'rich',
    component: () => import(/* webpackChunkName: "about" */ '../views/Rich/rich.vue') // 富文本
  },
  {
    path: '/task',
    name: 'task',
    component: () => import(/* webpackChunkName: "about" */ '../views/Task/task.vue') // 做单
  },
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "about" */ '../views/Order/order.vue') // 订单
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import(/* webpackChunkName: "about" */ '../views/Recharge/recharge.vue') // 充值
  },
  {
    path: '/service',
    name: 'service',
    component: () => import(/* webpackChunkName: "about" */ '../views/Service/service.vue') // 客服
  },
  {
    path: '/total',
    name: 'total',
    component: () => import(/* webpackChunkName: "about" */ '../views/Total/total.vue') // 总收益
  },
  {
    path: '/pledge',
    name: 'pledge',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pledge/pledge.vue') // 质押
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: () => import(/* webpackChunkName: "about" */ '../views/Withdraw/withdraw.vue') // 提现
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import(/* webpackChunkName: "about" */ '../views/Wallet/wallet.vue') // 钱包
  },
  {
    path: '/security',
    name: 'security',
    component: () => import(/* webpackChunkName: "about" */ '../views/Security/security.vue') // 安全
  },
  {
    path: '/invite',
    name: 'invite',
    component: () => import(/* webpackChunkName: "about" */ '../views/Invite/invite.vue') // 邀请
  },
  {
    path: '/gift',
    name: 'gift',
    component: () => import(/* webpackChunkName: "about" */ '../views/Gift/gift.vue') // 抽奖
  }

]

const router = new VueRouter({
  routes
})

// 自定义重复路由跳转的逻辑
const isAlreadyAtPath = (to, from) => {
  const alreadyAtPath = from.matched.some(record => {
    return record.path === to.path;
  });
  return alreadyAtPath;
};
 
router.beforeEach((to, from, next) => {
  if (isAlreadyAtPath(to, from)) {
    // 如果目标路由已经在当前路由链中，则允许跳转
    next();
  } else {
    // 否则，执行正常的跳转逻辑
    next();
  }
});

export default router
