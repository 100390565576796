export default {
    "首页": "Inicio",
    "关于": "Acerca de",
    "金融": "Finanzas",
    "安全": "Seguridad",
    "用户名": "Nombre de usuario",
    "可信度": "Credibilidad",
    "存款本金": "Capital depositado",
    "利息": "Interés",
    "开采进度": "Progreso de minería",
    "订单": "Orden",
    "充值": "Recargar",
    "总收入": "Ingresos totales",
    "质押收益": "Ingresos por pignoración",
    "提现": "Retiro",
    "钱包": "Billetera",
    "证书": "Certificado",
    "目标和控制": "T&C",
    "常见问题": "Preguntas Frecuentes",
    "电子邮件": "Correo electrónico",
    "等级": "Nivel",
    "活动": "Actividad",
    "登录": "Iniciar sesión",
    "注册": "Registrarse",
    "排名": "Clasificación",
    "矿工": "Minero",
    "市值": "Capitalización de mercado",
    "价格": "Precio",
    "成交量": "Volumen",
    "生产的PoW（24小时）": "PoW producido (24h)",
    "哈希率": "Tasa de hash",
    "算法": "Algoritmo",
    "剩余供应": "Suministro restante",
    "请输入您的账户": "Por favor, ingrese su cuenta",
    "账户": "Cuenta",
    "请输入密码": "Por favor, ingrese su contraseña",
    "请输入您的电话号码": "Por favor, ingrese su número de teléfono",
    "请输入用户名": "Por favor, ingrese su nombre de usuario",
    "请确认密码": "Por favor, confirme su contraseña",
    "确认密码": "Confirmar contraseña",
    "邀请码": "Código de invitación",
    "请输入邀请码": "Por favor, ingrese el código de invitación",
    "邮箱": "Correo electrónico",
    "请输入您的邮箱": "Por favor, ingrese su correo electrónico",
    "用户协议": "Acuerdo del usuario",
    "注销": "Cerrar sesión",
    "复制成功": "Copiado con éxito",
    "硬币": "Monedas",
    "功耗": "Consumo de energía",
    "单位功率": "Potencia por unidad",
    "生产（24小时）": "Producción (24h)",
    "收入（24小时）": "Ingresos (24h)",
    "每日成本（24小时）（0.06美元0）/千瓦时": "Costo diario (24h) ($0.06/kWh)",
    "利润（24小时）": "Beneficio (24h)",
    "保本价格": "Precio de equilibrio",
    "日收入": "Ingresos diarios",
    "连接到世界领先的加密货币矿池。": "Conéctese al principal grupo de minería de criptomonedas del mundo.",
    "什么是比特币": "¿Qué es Bitcoin?",
    "云挖矿": "Minería en la nube",
    "全球领先的多币种综合矿池": "Principal grupo de minería multifuncional del mundo",
    "支持多币种挖矿，多个子账户管理，轻松管理观察者链接共享": "Soporta minería multimoneda, gestión de múltiples subcuentas, fácil gestión de enlaces de observadores.",
    "账户余额": "Saldo de cuenta",
    "当前矿工": "Minero actual",
    "我的挖矿进度": "Mi progreso de minería",
    "全部": "Todo",
    "待定": "Pendiente",
    "完成": "Completado",
    "做单": "Crear orden",
    "温馨提示：请联系在线客服获取最新充值地址": "Recordatorio: Por favor, contacte al servicio al cliente en línea para obtener la última dirección de recarga.",
    "成功": "Éxito",
    "充值历史": "Historial de recargas",
    "订单号": "Número de orden",
    "订单金额": "Monto de la orden",
    "订单状态": "Estado del pedido",
    "邀请": "Invitar",
    "客服": "Servicio al cliente",
    "本金合计": "Capital total",
    "今天的矿业利润": "Beneficio minero de hoy",
    "奖金": "Bono",
    "冻结金额": "Cantidad congelada",
    "获胜": "Ganar",
    "历史": "Historia",
    "质押收益规则": "Reglas de ingresos por pignoración",
    "收入记录": "Registros de ingresos",
    "规则": "Reglas",
    "您将在5-10分钟内收到提款": "Recibirá el retiro en 5-10 minutos.",
    "提现历史": "Historial de retiros",
    "请注意，提款请求是即时的，但资金可能需要5-30分钟才能到达您的提款地址。提款方式提款将被转发到提供的地址。": "Tenga en cuenta que las solicitudes de retiro son instantáneas, pero los fondos pueden tardar de 5 a 30 minutos en llegar a su dirección de retiro. Los retiros se reenviarán a la dirección proporcionada.",
    "提款方式": "Método de retiro",
    "提款将被转发到提供的地址。": "Los retiros se reenviarán a la dirección proporcionada.",
    "钱包地址": "Dirección de billetera",
    "请输入金额": "Por favor, ingrese la cantidad",
    "提现密码": "Contraseña de retiro",
    "提款": "Retirar",
    "提款记录": "Registros de retiro",
    "钱包信息": "Información de billetera",
    "请输入钱包地址": "Por favor, ingrese la dirección de la billetera",
    "请重新输入密码": "Por favor, vuelva a ingresar su contraseña",
    "绑定": "Vincular",
    "登录密码": "Contraseña de inicio de sesión",
    "提款密码": "Contraseña de retiro",
    "密码重置后，旧密码无效，请记住密码": "Después de restablecer la contraseña, la contraseña anterior es inválida; por favor, recuerde la nueva contraseña.",
    "请输入原始密码": "Por favor, ingrese la contraseña original",
    "确认": "Confirmar",
    "个人资料邀请码": "Código de invitación de perfil",
    "支持服务": "Servicios de soporte",
    "如果您有任何问题，请联系我们": "Si tiene alguna pregunta, por favor contáctenos.",
    "他们都获奖了": "Todos ganaron premios.",
    "我的奖品": "Mis premios",
    "抽奖次数": "Entradas para la rifa",
    "活动描述": "Descripción de la actividad",
    "密码": "Contraseña",
    "电话号码": "Número de teléfono",
    "工作日": "Días hábiles",
    "矿物开采": "Minería de minerales",
    "今天的收益": "Ganancias de hoy",
    "算力收益参考": "referencia de ingresos de cálculo de poder",
    "计算能力": "capacidad de cálculo",
    "收入模式": "modelo de ingresos",
    "加速": "aceleración",
    "进展速度": "velocidad de progreso",
    "困难": "difícil",
    "返佣": "Devolución de Comisiones",
    "尚未绑定提款地址，请先绑定提款地址！": "¡Todavía no se ha vinculado la dirección de retiro, ¡ por favor, vincule la dirección de retiro primero!",
    "复制": "Copiar",
    "暂无抽奖次数": "¡¡ no hay sorteos por el momento!",
    "待处理": "Failure",
    "失败": "fracaso",
    "状态": "Estado",
    "交易密码": "contraseña de transacción",
    "请输入交易密码": "introduzca la contraseña de la transacción",
    "系统错误": "Error del sistema",
    "暂无数据": "No hay datos por el momento",
    "请输入提款密码": "Por favor, introduzca la contraseña de retiro",
    "如果您需要修改存款地址，请联系最新的在线客服进行修改": "Si necesita modificar la dirección del depósito, Póngase en contacto con el último servicio al cliente en línea para modificarlo.",
    "今日佣金": "Comisión de hoy",
    "总佣金": "Comisión total",
    "当前矿工等级": "Nivel actual de mineros",
    "可领取奖金": "Se puede recibir un bono",
    "每日BT级别奖金": "Bonificación diaria de nivel BT",
    "领取": "Recoger",
    "矿机每天完成3组挖矿任务可以领取": "La máquina minera puede completar tres grupos de tareas de minería todos los días para recogerlas.",
    "姓名": "Nombre",
    "请输入您的姓名": "Introduzca su nombre",
    "现在提交": "Presentado ahora",
    "奖金已经自动发放至您的工作账户中": "El bono se ha pagado automáticamente a su cuenta de trabajo"
}
