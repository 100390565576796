<template>
  <div class="footer">
    <div :class="isMobile ? 'footer-box-m' : 'footer-box'">
      <div :class="isMobile ? 'footer-left-m' : 'footer-left'">
        <img :class="isMobile ? 'm-logo' : 'p-logo'" src="../assets/logo.png" />
        <div class="btn-bottom" v-if="!isMobile">
          <svg
            width="28"
            height="28"
            viewBox="0 0 26 26"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.36 11.918l18.83-7.873a.584.584 0 01.793.685l-4.69 18.825a.587.587 0 01-.943.309l-4.073-3.405a.584.584 0 00-.789.037L10.4 22.59a.584.584 0 01-.998-.414V17.75c0-.156.061-.305.171-.415l8.495-8.524-10.029 7.548a.584.584 0 01-.726-.019L3.21 12.911a.589.589 0 01.15-.993z"
              fill="#d8dce9"
              fill-rule="nonzero"
            ></path>
          </svg>
          <svg
            width="28"
            height="28"
            viewBox="0 0 26 26"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.455 7.181a9.88 9.88 0 01-2.764.733 4.698 4.698 0 002.115-2.574c-.93.533-1.96.92-3.055 1.129A4.883 4.883 0 0018.238 5c-2.657 0-4.812 2.083-4.812 4.652 0 .365.043.72.125 1.06-4-.193-7.545-2.046-9.918-4.86A4.513 4.513 0 002.98 8.19c0 1.614.85 3.038 2.14 3.872a4.917 4.917 0 01-2.179-.582v.058c0 2.254 1.659 4.135 3.86 4.562a4.984 4.984 0 01-2.173.08c.612 1.848 2.39 3.194 4.495 3.231a9.868 9.868 0 01-5.976 1.992c-.389 0-.772-.023-1.148-.066a13.96 13.96 0 007.376 2.09c8.851 0 13.691-7.088 13.691-13.236 0-.202-.004-.403-.014-.602a9.597 9.597 0 002.402-2.409z"
              fill="#d8dce9"
              fill-rule="nonzero"
            ></path>
          </svg>
          <svg
            width="28"
            height="28"
            viewBox="0 0 26 26"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#d8dce9"
              fill-rule="nonzero"
              d="M20.6360917,6.50749594 C19.2119924,5.80324983 17.689278,5.29143306 16.0973219,5 C15.9018106,5.3736068 15.6733974,5.87611607 15.5159148,6.27586036 C13.8236246,6.00686729 12.1468956,6.00686729 10.485728,6.27586036 C10.3282755,5.87611607 10.0946752,5.3736068 9.89741474,5 C8.30373967,5.29143306 6.77927614,5.80513046 5.35517691,6.51122531 C2.4827559,11.099025 1.70408978,15.5728715 2.09342359,19.9831913 C3.99856421,21.4869259 5.844875,22.4003995 7.66002703,22.9981513 C8.10819819,22.346212 8.5079062,21.6531859 8.85224496,20.9228023 C8.19643915,20.6594192 7.56831796,20.3343899 6.97481762,19.9570538 C7.13227012,19.8337613 7.28628466,19.7048588 7.43508196,19.572227 C11.0550118,21.3617847 14.9881579,21.3617847 18.5648418,19.572227 C18.7153882,19.7048588 18.8693726,19.8337613 19.0251062,19.9570538 C18.4298567,20.3362387 17.8000165,20.661268 17.1442107,20.9246829 C17.4885495,21.6531859 17.8865385,22.3480926 18.3364286,23 C20.1533298,22.4022482 22.0013535,21.4888066 23.9064957,19.9831913 C24.363322,14.8705061 23.1261091,10.4377464 20.6360917,6.50749594 Z M9.65202922,18 C8.60103305,18 7.73913043,16.873171 7.73913043,15.5009714 C7.73913043,14.1287718 8.58262829,13 9.65202922,13 C10.7214593,13 11.5833328,14.1267955 11.564928,15.5009714 C11.5665906,16.873171 10.7214593,18 9.65202922,18 Z M16.3476814,18 C15.2966852,18 14.4347826,16.873171 14.4347826,15.5009714 C14.4347826,14.1287718 15.2782513,13 16.3476814,13 C17.4170823,13 18.278985,14.1267955 18.2605802,15.5009714 C18.2605802,16.873171 17.4170823,18 16.3476814,18 Z"
            ></path>
          </svg>
          <svg
            width="28"
            height="28"
            viewBox="0 0 26 26"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.843 14.587v-4.034h-3.325v-2.42s0-1.1 1.229-1.1H19V3h-5.205c-3.614 0-3.903 4.327-3.903 4.327v3.153H7v4.033h2.892V25h4.554V14.587h3.397z"
              fill="#d8dce9"
              fill-rule="nonzero"
            ></path>
          </svg>
          <svg
            width="28"
            height="28"
            viewBox="0 0 26 26"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.744 23h3.687v-7.23c0-.359.048-.742.145-.973.242-.718.85-1.46 1.869-1.46 1.333 0 1.869 1.1 1.869 2.719V23H22v-7.437c0-3.692-1.82-5.383-4.246-5.383a4.285 4.285 0 00-3.662 2.001h-.073l-.169-1.718h-3.203c.049 1.103.097 2.436.097 4V23zM5.942 5C4.753 5 3.976 5.846 4 6.974c-.025 1.077.752 1.95 1.917 1.95 1.212 0 1.99-.873 1.99-1.95C7.883 5.846 7.13 5 5.942 5zm1.845 18V10.463h-3.69V23h3.69z"
              fill="#d8dce9"
              fill-rule="nonzero"
            ></path>
          </svg>
        </div>
      </div>
      <div :class="isMobile ? 'footer-right-m' : 'footer-right'">
        <div class="item" v-if="!isMobile">
          <p class="title">Hive OS</p>
          <p class="content">Start using</p>
          <p class="content">Why choose Hive OS</p>
          <p class="content">Price</p>
          <p class="content">Recommended Plan</p>
          <p class="content">CONTACT INFORMATION</p>
          <p class="content">Hive OS Api</p>
          <p class="content">Terms of Use</p>
          <p class="content">Vulnerability Disclosure Policy</p>
          <p class="content">Service status</p>
        </div>
        <div class="item">
          <p class="title">Content</p>
          <p class="content">GitHub</p>
          <p class="content">Change log</p>
          <p class="content">Statistics</p>
          <p class="content">Blog</p>
        </div>
        <div class="item">
          <p class="title">Community</p>
          <p class="content">Forum</p>
          <p class="content">EN</p>
          <p class="content">FR</p>
          <p class="content">IT</p>
          <p class="content">ES</p>
        </div>
        <div class="item" v-if="!isMobile">
          <p class="title">Other</p>
          <p class="content">Hiveon Mining Pool</p>
          <p class="content">Hiveon FAQS</p>
          <p class="content">Hiveon Mining Pool Rules</p>
          <p class="content">ASIC Firmware</p>
          <p class="content">ASIC Hub</p>
          <p class="content">Energy</p>
          <p class="content">Terms of Use</p>
        </div>
        <div class="item">
          <p class="title">Customer Support</p>
          <p class="content">riot@riot-feedback.com</p>
          <p class="content">Knowledge Base</p>
        </div>
      </div>
    </div>
    <div :class="isMobile ? 'm-bottom' : 'p-bottom'">
        © 2013–2024 F2Pool Copyright v1.2.58 
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    // 映射 Vuex 的 getter 判断当前登录端
    ...mapGetters("device", ["isMobile"]),
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: column;
  background: #1e4de7;

  // pc样式
  .footer-box {
    width: 60%;
    margin: 40px auto;
    display: flex;
    position: relative;

    .footer-left {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .p-logo {
        width: 257px;
        height: 60px;
      }
      .btn-bottom {
        display: flex;
        flex-direction: column;

        svg {
          margin-top: 10px;
        }
      }
    }

    .footer-right {
      width: 70%;
      display: flex;
      justify-content: space-between;
      color: #fff;
      font-size: 14px;

      .item {
        flex: 1;
        color: #d8dce9;

        .title {
          color: #fff;
        }
      }
    }
  }

  .p-bottom {
    width: 60%;
    text-align: center;
    color: #fff;
    font-size: 12px;
    border-top: 1px solid #3e60cf;
    margin: 0 auto;
    padding: 10px 0;
  }

  // 移动样式
  .footer-box-m {
    width: 100%;
    margin: 40px 0;
    display: flex;
    position: relative;
  }
  .footer-left-m {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn-bottom {
      display: flex;
      flex-direction: column;

      svg {
        margin-top: 10px;
      }
    }

    .m-logo {
      width: 100px;
      height: 25px;
      position: absolute;
      top: -20px;
      left: calc(50% - 50px);
    }
  }

  .footer-right-m {
    width: 100%;
        display: flex;
      justify-content: space-between;
      color: #fff;
      font-size: 14px;
      margin-top: 20px;

      .item {
        flex: 1;
        color: #d8dce9;
        padding:0 10px;

        .title {
          color: #fff;
        }
    }
  }

  .m-bottom {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 12px;
    border-top: 1px solid #3e60cf;
    margin: 0 auto;
    padding: 10px 0;
  }
}
</style>