export default {
    "首页": "首页",
    "关于": "关于",
    "金融": "金融",
    "安全": "安全",
    "用户名": "用户名",
    "可信度": "可信度",
    "存款本金": "存款本金",
    "利息": "利息",
    "开采进度": "开采进度",
    "订单": "订单",
    "充值": "充值",
    "总收入": "总收入",
    "质押收益": "质押收益",
    "提现": "提现",
    "钱包": "钱包",
    "证书": "证书",
    "目标和控制": "目标和控制",
    "常见问题": "常见问题",
    "电子邮件": "电子邮件",
    "等级": "等级",
    "活动": "活动",
    "登录": "登录",
    "注册": "注册",
    "排名": "排名",
    "矿工": "矿工",
    "市值": "市值",
    "价格": "价格",
    "成交量": "成交量",
    "生产的PoW（24小时）": "生产的PoW（24小时）",
    "哈希率": "哈希率",
    "算法": "算法",
    "剩余供应": "剩余供应",
    "请输入您的账户": "请输入您的账户",
    "账户": "账户",
    "请输入密码": "请输入密码",
    "请输入您的电话号码": "请输入您的电话号码",
    "请输入用户名": "请输入用户名",
    "请确认密码": "请确认密码",
    "确认密码": "确认密码",
    "邀请码": "邀请码",
    "请输入邀请码": "请输入邀请码",
    "邮箱": "邮箱",
    "请输入您的邮箱": "请输入您的邮箱",
    "用户协议": "用户协议",
    "注销": "注销",
    "复制成功": "复制成功",
    "硬币": "硬币",
    "功耗": "功耗",
    "单位功率": "单位功率",
    "生产（24小时）": "生产（24小时）",
    "收入（24小时）": "收入（24小时）",
    "每日成本（24小时）（0.06美元0）/千瓦时": "每日成本（24小时）（0.06美元0）/千瓦时",
    "利润（24小时）": "利润（24小时）",
    "保本价格": "保本价格",
    "日收入": "日收入",
    "连接到世界领先的加密货币矿池。": '连接到世界领先的加密货币矿池。',
    "什么是比特币": "什么是比特币",
    "云挖矿": "云挖矿",
    "全球领先的多币种综合矿池": "全球领先的多币种综合矿池",
    "支持多币种挖矿，多个子账户管理，轻松管理观察者链接共享": "支持多币种挖矿，多个子账户管理，轻松管理观察者链接共享",
    "账户余额": "账户余额",
    "当前矿工": "当前矿工",
    "我的挖矿进度": "我的挖矿进度",
    "全部": "全部",
    "待定": "待定",
    "完成": "完成",
    "做单": "做单",
    "温馨提示：请联系在线客服获取最新充值地址": "温馨提示：请联系在线客服获取最新充值地址",
    "成功": "成功",
    "充值历史": "充值历史",
    "订单号": "订单号",
    "订单金额": " 订单金额",
    "订单状态": "订单状态",
    "邀请": "邀请",
    "客服": "客服",
    "本金合计": "本金合计",
    "今天的矿业利润": "今天的矿业利润",
    "奖金": "奖金",
    "冻结金额": "冻结金额",
    "获胜": "获胜",
    "历史": "历史",
    "质押收益规则": "质押收益规则",
    "收入记录": "收入记录",
    "规则": "规则",
    "您将在5-10分钟内收到提款": "您将在5-10分钟内收到提款",
    "提现历史": "提现历史",
    "请注意，提款请求是即时的，但资金可能需要5-30分钟才能到达您的提款地址。提款方式提款将被转发到提供的地址。": "请注意，提款请求是即时的，但资金可能需要5-30分钟才能到达您的提款地址。提款方式提款将被转发到提供的地址。",
    "提款方式": "提款方式",
    "提款将被转发到提供的地址。": "提款将被转发到提供的地址。",
    "钱包地址": "钱包地址",
    "请输入金额": "请输入金额",
    "提现密码": "提现密码",
    "提款": "提款",
    "提款记录": "提款记录",
    "钱包信息": "钱包信息",
    "请输入钱包地址": "请输入钱包地址",
    "请重新输入密码": "请重新输入密码",
    "绑定": "绑定",
    "登录密码": "登录密码",
    "提款密码": "提款密码",
    "请输入提款密码": "请输入提款密码",
    "密码重置后，旧密码无效，请记住密码": "密码重置后，旧密码无效，请记住密码",
    "请输入原始密码": "请输入原始密码",
    "确认": "确认",
    "个人资料邀请码": "个人资料邀请码",
    "支持服务": "支持服务",
    "如果您有任何问题，请联系我们": "如果您有任何问题，请联系我们",
    "他们都获奖了": "他们都获奖了",
    "我的奖品": "我的奖品",
    "抽奖次数": "抽奖次数",
    "活动描述": "活动描述",
    "密码": "密码",
    "电话号码": "电话号码",
    "工作日": "工作日",
    "矿物开采": "矿物开采",
    "今天的收益": "今天的收益",
    "算力收益参考": "算力收益参考",
    "计算能力": "计算能力",
    "收入模式": "收入模式",
    "加速": "加速",
    "进展速度": "进展速度",
    "困难": "困难",
    "返佣": "返佣",
    "尚未绑定提款地址，请先绑定提款地址！": "尚未绑定提款地址，请先绑定提款地址！",
    "复制": "复制",
    "暂无抽奖次数": "暂无抽奖次数",
    "待处理": "待处理",
    "失败": "失败",
    "状态": "状态",
    "交易密码": "交易密码",
    "请输入交易密码": "请输入交易密码",
    "系统错误": "系统错误",
    "暂无数据": "暂无数据",
    "如果您需要修改存款地址，请联系最新的在线客服进行修改": "如果您需要修改存款地址，请联系最新的在线客服进行修改",
    "今日佣金": "今日佣金",
    "总佣金": "总佣金",
    "当前矿工等级": "当前矿工等级",
    "可领取奖金": "可领取奖金",
    "每日BT级别奖金": "每日BT级别奖金",
    "领取": "领取",
    "矿机每天完成3组挖矿任务可以领取": "矿机每天完成3组挖矿任务可以领取",
    "姓名": "姓名",
    "请输入您的姓名": "请输入您的姓名",
    "现在提交": "现在提交",
    "奖金已经自动发放至您的工作账户中": "奖金已经自动发放至您的工作账户中"
}