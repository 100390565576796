const state = {
    isLogin: false, // 当前是否登录
    userInfo: {} // 用户信息
  }
  
  const mutations = {
    SET_LOGIN_STATE (state, isLogin) {
      state.isLogin = isLogin
    },
    SET_INFO_STATE (state, userInfo) {
      state.userInfo = userInfo
    }
  }
  
  const actions = {
    // 判断当前设备是否登录
    checkLoginType ({ commit }) {
      const isLogin = localStorage.getItem('token') ? true : false
      commit("SET_LOGIN_STATE", isLogin)
    },
    checkUserInfo ({ commit }) {
      const userInfo = localStorage.getItem('info') ? JSON.parse(localStorage.getItem('info')) : {}
      commit("SET_INFO_STATE", userInfo)
    },
  }
  
  const getters = {
    isLogin: (state) => state.isLogin,
    userInfo: (state) => state.userInfo
  }
  
  export default {
    namespaced: true, // 启用命名空间
    state,
    mutations,
    actions,
    getters
  }