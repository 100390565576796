import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import VueI18n from 'vue-i18n'
import i18n from './i18n/index'
import VueClipboard from 'vue-clipboard2'
import navBar from './components/navBar.vue'
import footers from './components/footer.vue'
import VueLuckyCanvas from '@lucky-canvas/vue';

import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueI18n)
Vue.use(VueClipboard)
Vue.use(VueLuckyCanvas)
Vue.component('nav-bar', navBar)
Vue.component('footers', footers)


new Vue({
  i18n,
  router,
  store,
  created () {
    this.$store.dispatch('device/checkDeviceType')
    this.$store.dispatch('login/checkLoginType')
  },
  render: h => h(App)
}).$mount('#app')
