export default {
    "首页": "Home",
    "关于": "About",
    "金融": "Finance",
    "安全": "Safety",
    "用户名": "Username",
    "可信度": "Credibility",
    "存款本金": "Deposit principal",
    "利息": "Interest",
    "开采进度": "Mining Progress",
    "订单": "Order",
    "充值": "Recharge",
    "总收入": "Total revenue",
    "质押收益": "Pledge income",
    "提现": "Withdrawal",
    "钱包": "Wallet",
    "证书": "Certificate",
    "目标和控制": "T&C",
    "常见问题": "FAQS",
    "电子邮件": "Email",
    "等级": "Level",
    "活动": "Activity",
    "登录": "Login",
    "注册": "Register",
    "排名": "Ranking",
    "矿工": "Miner",
    "市值": "Market Cap",
    "价格": "Price",
    "成交量": "Volume",
    "生产的PoW（24小时）": "PoW Produced (24h)",
    "哈希率": "Hash Rate",
    "算法": "Algorithm",
    "剩余供应": "Remaining Supply",
    "请输入您的账户": "Please enter your account",
    "账户": "Account",
    "请输入密码": "Please enter your password",
    "请输入您的电话号码": "Please enter your phone number",
    "请输入用户名": "Please enter your username",
    "请确认密码": "Please confirm your password",
    "确认密码": "Confirm Password",
    "邀请码": "Invitation Code",
    "请输入邀请码": "Please enter the invitation code",
    "邮箱": "Email",
    "请输入您的邮箱": "Please enter your email",
    "用户协议": "User Agreement",
    "注销": "Logout",
    "复制成功": "Copy Successful",
    "硬币": "Coins",
    "功耗": "Power Consumption",
    "单位功率": "Unit Power",
    "生产（24小时）": "Production (24h)",
    "收入（24小时）": "Income (24h)",
    "每日成本（24小时）（0.06美元0）/千瓦时": "Daily Cost (24h) ($0.06/kWh)",
    "利润（24小时）": "Profit (24h)",
    "保本价格": "Break-even Price",
    "日收入": "Daily Income",
    "连接到世界领先的加密货币矿池。": "Connect to the world's leading cryptocurrency mining pool.",
    "什么是比特币": "What is Bitcoin?",
    "云挖矿": "Cloud Mining",
    "全球领先的多币种综合矿池": "World's Leading Multi-Currency Comprehensive Mining Pool",
    "支持多币种挖矿，多个子账户管理，轻松管理观察者链接共享": "Supports multi-currency mining, multiple sub-account management, easy observer link sharing.",
    "账户余额": "Account Balance",
    "当前矿工": "Current Miner",
    "我的挖矿进度": "My Mining Progress",
    "全部": "All",
    "待定": "Pending",
    "完成": "Completed",
    "做单": "Create Order",
    "温馨提示：请联系在线客服获取最新充值地址": "Warm reminder: Please contact online customer service for the latest recharge address.",
    "成功": "Success",
    "充值历史": "Recharge History",
    "订单号": "Order Number",
    "订单金额": "Order Amount",
    "订单状态": "Order Status",
    "邀请": "Invite",
    "客服": "Customer Service",
    "本金合计": "Total Principal",
    "今天的矿业利润": "Today's Mining Profit",
    "奖金": "Bonus",
    "冻结金额": "Frozen Amount",
    "获胜": "Win",
    "历史": "History",
    "质押收益规则": "Staking Revenue Rules",
    "收入记录": "Income Records",
    "规则": "Rules",
    "您将在5-10分钟内收到提款": "You will receive the withdrawal in 5-10 minutes.",
    "提现历史": "Withdrawal History",
    "请注意，提款请求是即时的，但资金可能需要5-30分钟才能到达您的提款地址。提款方式提款将被转发到提供的地址。": "Please note that withdrawal requests are instant, but funds may take 5-30 minutes to reach your withdrawal address. Withdrawals will be forwarded to the provided address.",
    "提款方式": "Withdrawal Method",
    "提款将被转发到提供的地址。": "Withdrawals will be forwarded to the provided address.",
    "钱包地址": "Wallet Address",
    "请输入金额": "Please enter the amount",
    "提现密码": "Withdrawal Password",
    "提款": "Withdraw",
    "提款记录": "Withdrawal Records",
    "钱包信息": "Wallet Information",
    "请输入钱包地址": "Please enter wallet address",
    "请重新输入密码": "Please re-enter your password",
    "绑定": "Bind",
    "登录密码": "Login Password",
    "提款密码": "Withdrawal Password",
    "密码重置后，旧密码无效，请记住密码": "After resetting the password, the old password is invalid; please remember the new password.",
    "请输入原始密码": "Please enter the original password",
    "确认": "Confirm",
    "个人资料邀请码": "Profile Invitation Code",
    "支持服务": "Support Services",
    "如果您有任何问题，请联系我们": "If you have any questions, please contact us.",
    "他们都获奖了": "They all won prizes.",
    "我的奖品": "My Prizes",
    "抽奖次数": "Raffle Entries",
    "活动描述": "Activity Description",
    "密码": "Password",
    "电话号码": "Phone Number",
    "工作日": "Working Days",
    "矿物开采": "Mineral Mining",
    "今天的收益": "Today's earnings",
    "算力收益参考": "Reference for Computing Power Returns",
    "计算能力": "Computational power",
    "收入模式": "Income Model",
    "加速": "Acceleration",
    "进展速度": "Progress speed",
    "困难": "Difficulty",
    "返佣": "Rebate",
    "尚未绑定提款地址，请先绑定提款地址！": "The withdrawal address has not been bound yet. Please bind the withdrawal address first!",
    "复制": "Copy",
    "暂无抽奖次数": "There are currently no lottery draws available!",
    "待处理": "Pending",
    "失败": "Failure",
    "状态": "status",
    "交易密码": "Transaction Password",
    "请输入交易密码": "Please enter transaction password",
    "系统错误": "System error",
    "暂无数据": "No data available at the moment",
    "请输入提款密码": "Please enter the withdrawal password",
    "如果您需要修改存款地址，请联系最新的在线客服进行修改": "If you need to modify your deposit address, please contact the latest online customer service for modification",
    "今日佣金": "Today's commission",
    "总佣金": "Total commission",
    "当前矿工等级": "Current miner level",
    "可领取奖金": "Can receive bonuses",
    "每日BT级别奖金": "Daily BT level bonus",
    "领取": "Receive",
    "矿机每天完成3组挖矿任务可以领取": "Mining machines can receive rewards for completing 3 sets of mining tasks per day",
    "姓名": "Full name",
    "请输入您的姓名": "Please enter your name",
    "现在提交": "Submit Now",
    "奖金已经自动发放至您的工作账户中": "The bonus has been automatically disbursed to your work account"
}