import Vue from "vue";
import VueI18n from "vue-i18n";

// 引入各个语言配置文件
import zh from "./zh-cn"; //简体中文
import en from "./en-es"; //英文
import es from "./es-mx"; //西班牙

Vue.use(VueI18n);
// 获取当前域名
let lang = localStorage.getItem("lang");

// if (!lang) {
//   if (!isShowEn) {
//     localStorage.setItem("locale", "th");
//   } else {
//     localStorage.setItem("locale", "en");
//   }
// }

// 创建vue-i18n实例i18n
const i18n = new VueI18n({
  // 设置默认语言
  locale: lang, // 语言标识
  // 添加多语言（每一个语言标示对应一个语言文件）
  messages: {
    'en_es': en,
    'zh_cn': zh,
    'es_mx': es
  },
});
// 暴露i18n
export default i18n;

