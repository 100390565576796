// store/modules/device.js

const state = {
  isMobile: false // 默认不是移动端
}

const mutations = {
  SET_MOBILE_STATE (state, isMobile) {
    state.isMobile = isMobile
  }
}

const actions = {
  // 判断当前设备是否是移动端
  checkDeviceType ({ commit }) {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    commit("SET_MOBILE_STATE", isMobile)
  }
}

const getters = {
  isMobile: (state) => state.isMobile
}

export default {
  namespaced: true, // 启用命名空间
  state,
  mutations,
  actions,
  getters
}
