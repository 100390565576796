import axios from './http';
import qs from 'qs'

// 定义 API 调用函数
// export function getExampleData() {
//   return axios.get('/example-data');
// }

// export function postExampleData(data) {
//   return axios.post('/example-data', data);
// }

// 富文本
export function rich(data) {
  return axios.get('index/my/detail?' + qs.stringify(data));
}
// 登录
export function login(data) {
  return axios.post('index/user/do_login', qs.stringify(data));
}
// 注册
export function register(data) {
  return axios.post('index/user/do_register', qs.stringify(data));
}
// 未登录列表
export function unList() {
  return axios.post('index/support/get_index_list');
}
// 登录后全部列表
export function allList() {
  return axios.post('index/index/get_index_list');
}
// 登录后分类列表
export function listData(data) {
  return axios.post('index/index/get_index_list_cate',data);
}
// 客服
export function service() {
  return axios.post('index/support/index');
}
// 个人信息
export function info() {
  return axios.post('index/my/index');
}
// 抽奖列表
export function giftList() {
  return axios.post('index/cj/index');
}
// 所有抽奖记录
export function allGiftRecord() {
  return axios.post('index/support/alljl');
}
// 抽奖记录
export function giftRecord() {
  return axios.post('index/cj/jl');
}
// 抽奖
export function addGift() {
  return axios.post('index/cj/add_cj');
}
// 获取订单
export function getOrder() {
  return axios.post('index/rot_order/submit_order');
}
// 获取订单信息
export function getOrderInfo(data) {
  return axios.post('index/order/order_info', data);
}
// 做单
export function doOrder(data) {
  return axios.post('index/order/do_order', data);
}
// 做单进度
export function orderInfo() {
  return axios.post('index/rot_order/index');
}
// 获取做单记录
export function record(data) {
  return axios.get('index/order/index?' + qs.stringify(data));
}
// 获取语言列表
export function langList() {
  return axios.get('index/user/common_parameters');
}
// 首页公告
export function notice() {
  return axios.post('index/support/get_index_msg');
}
// 收益接口
export function home() {
  return axios.post('index/index/home');
}
// 账户收益记录
export function caiwu(data) {
  return axios.get('index/my/caiwu?' + qs.stringify(data));
}
// 提现记录
export function withdrawRecord(data) {
  return axios.get('index/ctrl/deposit_admin?' + qs.stringify(data));
}
// 提款地址列表
export function address() {
  return axios.get('index/my/bind_bank');
}
// 绑定提款地址
export function add_address(data) {
  return axios.post('index/my/bind_bank', data);
}
// 提现
export function withdraw(data) {
  return axios.post('index/ctrl/do_deposit', data);
}
// 修改密码
export function setPassword(data) {
  return axios.post('index/ctrl/set_pwd', data);
}
// 每日做单领奖
export function getReward() {
  return axios.post('index/my/get_reward');
}


