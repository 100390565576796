<template>
  <div class="nav-bar">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      background-color="#1e4de7"
      mode="horizontal"
      active-text-color="#fff"
      text-color="#fff"
      @select="handleSelect"
    >
      <el-row>
        <el-col :span="3" v-if="!isMobile">&nbsp;</el-col>
        <!-- 移动端功能栏 -->
        <el-col :span="4" v-if="isMobile && isLogin">
          <el-menu-item index="11">
            <i class="el-icon-s-unfold" style="color: #fff"></i>
          </el-menu-item>
        </el-col>
        <el-col :span="isMobile ? 8 : 2">
          <el-menu-item>
            <img
              :class="isMobile ? 'blogo' : 'logo'"
              src="../assets/logo.png"
            />
          </el-menu-item>
        </el-col>
        <!-- 是否登录 -->
        <el-col v-if="!isMobile && !isLogin" :span="isLogin ? 6 : 6"
          >&nbsp;</el-col
        >
        <!-- 移动端隐藏部分 -->
        <div v-if="!isMobile && isLogin">
          <el-col :span="2">
            <el-menu-item index="/">{{ $t("首页") }}</el-menu-item>
          </el-col>
          <el-col :span="2">
            <el-submenu index="2">
              <template slot="title">
                <span>{{ $t("关于") }}</span>
                <i class="el-icon-caret-bottom" style="color: #fff"></i>
              </template>
              <el-menu-item index="1">
                <img
                  class="p-icon"
                  style="width: 20px; height: 20px; margin-right: 10px"
                  src="../assets/icon/8.png"
                />
                <span>{{ $t("证书") }}</span>
              </el-menu-item>
              <el-menu-item index="2">
                <img
                  class="p-icon"
                  style="width: 20px; height: 20px; margin-right: 10px"
                  src="../assets/icon/9.png"
                />
                <span>{{ $t("目标和控制") }}</span>
              </el-menu-item>
              <el-menu-item index="3">
                <img
                  class="p-icon"
                  style="width: 20px; height: 20px; margin-right: 10px"
                  src="../assets/icon/10.png"
                />
                <span>{{ $t("常见问题") }}</span>
              </el-menu-item>
              <el-menu-item index="4">
                <img
                  class="p-icon"
                  style="width: 20px; height: 20px; margin-right: 10px"
                  src="../assets/icon/11.png"
                />
                <span>{{ $t("电子邮件") }}</span>
              </el-menu-item>
              <el-menu-item index="5">
                <img
                  class="p-icon"
                  style="width: 20px; height: 20px; margin-right: 10px"
                  src="../assets/icon/12.png"
                />
                <span>{{ $t("等级") }}</span>
              </el-menu-item>
              <el-menu-item index="6">
                <img
                  class="p-icon"
                  style="width: 20px; height: 20px; margin-right: 10px"
                  src="../assets/icon/13.png"
                />
                <span>{{ $t("活动") }}</span>
              </el-menu-item>
            </el-submenu>
          </el-col>
          <el-col :span="2">
            <el-submenu index="3">
              <template slot="title">
                <span>{{ $t("金融") }}</span>
                <i class="el-icon-caret-bottom" style="color: #fff"></i>
              </template>
              <el-menu-item index="/task">
                <img
                  class="p-icon"
                  style="width: 20px; height: 20px; margin-right: 10px"
                  src="../assets/icon/2.png"
                />
                <span>{{ $t("开采进度") }}</span>
              </el-menu-item>
              <el-menu-item index="/order">
                <img
                  class="p-icon"
                  style="width: 20px; height: 20px; margin-right: 10px"
                  src="../assets/icon/3.png"
                />
                <span>{{ $t("订单") }}</span>
              </el-menu-item>
              <el-menu-item index="/recharge">
                <img
                  class="p-icon"
                  style="width: 20px; height: 20px; margin-right: 10px"
                  src="../assets/icon/4.png"
                />
                <span>{{ $t("充值") }}</span>
              </el-menu-item>
              <el-menu-item index="/total">
                <img
                  class="p-icon"
                  style="width: 20px; height: 20px; margin-right: 10px"
                  src="../assets/icon/5.png"
                />
                <span>{{ $t("总收入") }}</span>
              </el-menu-item>
              <el-menu-item index="/pledge">
                <img
                  class="p-icon"
                  style="width: 20px; height: 20px; margin-right: 10px"
                  src="../assets/icon/6.png"
                />
                <span>{{ $t("质押收益") }}</span>
              </el-menu-item>
              <el-menu-item index="/withdraw">
                <img
                  class="p-icon"
                  style="width: 20px; height: 20px; margin-right: 10px"
                  src="../assets/icon/7.png"
                />
                <span>{{ $t("提现") }}</span>
              </el-menu-item>
              <el-menu-item index="/wallet">
                <img
                  class="p-icon"
                  style="width: 20px; height: 20px; margin-right: 10px"
                  src="../assets/icon/8.png"
                />
                <span>{{ $t("钱包") }}</span>
              </el-menu-item>
            </el-submenu>
          </el-col>
          <el-col :span="2">
            <el-submenu index="4">
              <template slot="title">
                <span>{{ $t("安全") }}</span>
                <i class="el-icon-caret-bottom" style="color: #fff"></i>
              </template>
              <el-menu-item index="/security">
                <img
                  class="p-icon"
                  style="width: 20px; height: 20px; margin-right: 10px"
                  src="../assets/icon/14.png"
                />
                <span>{{ $t("安全") }}</span>
              </el-menu-item>
              <el-menu-item index="/invite">
                <img
                  class="p-icon"
                  style="width: 20px; height: 20px; margin-right: 10px"
                  src="../assets/icon/15.png"
                />
                <span>{{ $t("邀请") }}</span>
              </el-menu-item>
              <el-menu-item index="/service">
                <img
                  class="p-icon"
                  style="width: 20px; height: 20px; margin-right: 10px"
                  src="../assets/icon/16.png"
                />
                <span>{{ $t("客服") }}</span>
              </el-menu-item>
            </el-submenu>
          </el-col>
        </div>
        <!-- 移动端隐藏部分 -->
        <el-col :span="3" v-if="!isMobile">&nbsp;</el-col>
        <!-- 移动端隐藏用户信息 -->
        <el-col :span="isMobile ? 4 : 2" v-if="!isMobile && isLogin">
          <el-menu-item index="5">
            <div
              class="user-card"
              style="position: relative"
              @mouseenter="showInfo = true"
              @mouseleave="showInfo = false"
            >
              <i class="el-icon-s-custom" style="color: #fff"></i>
              <span>{{ info.username }}</span>
              <i class="el-icon-caret-bottom" style="color: #fff"></i>

              <!-- 个人信息 -->
              <div v-if="showInfo" class="info-box">
                <div class="info-top">
                  <img :src="info.headpic" />
                  <div class="top-right">
                    <span>{{ info.username }}</span>
                    <div class="invite">
                      <span>{{ $t("邀请码") }}：</span>
                      <span>{{ info.invite_code }}</span>
                      <img
                        class="copy"
                        src="../assets/icon/copy.png"
                        v-clipboard:copy="invite"
                        v-clipboard:success="onSuccess"
                      />
                    </div>
                  </div>
                </div>
                <div class="credible">{{ $t("可信度") }}：100%&nbsp;BT{{ info.level }}</div>
                <!-- money -->
                <div class="money">
                  <div class="interest">
                    <span>{{ info.yon3 ? info.yon3 : "0.00" }}</span>
                    <span class="text">{{ $t("利息") }}</span>
                  </div>
                  <div class="deposit">
                    <span>{{ info.balance }}</span>
                    <span class="text">{{ $t("存款本金") }}</span>
                  </div>
                </div>
                <!-- logOut -->
                <div class="logout" @click="logOut">
                  <img src="../assets/icon/logout.png" />
                  <span>{{ $t("注销") }}</span>
                </div>
              </div>
            </div>
          </el-menu-item>
        </el-col>
        <el-col :span="isMobile ? 10 : 4" v-if="!isLogin">
          <el-menu-item index="5">
            <el-button
              type="primary"
              :size="isMobile ? 'mini' : 'medium'"
              @click="toLogin"
              >{{ $t("登录") }}</el-button
            >
            <el-button
              :size="isMobile ? 'mini' : 'medium'"
              @click="toRegister"
              >{{ $t("注册") }}</el-button
            >
          </el-menu-item>
        </el-col>
        <!-- 当前是否登录 -->
        <el-col :span="5" v-if="isMobile && isLogin">&nbsp;</el-col>
        <!-- 切换语言 -->
        <el-col :span="isMobile ? 4 : 1">
          <el-submenu index="6">
            <template slot="title">
              <img v-if="!isMobile" class="lang" :src="newLang.url" />
              <span>{{ newLang.name }}</span>
              <i
                v-if="!isMobile"
                class="el-icon-caret-bottom"
                style="color: #fff"
              ></i>
              <i v-else class="el-icon-arrow-down" style="color: #fff"></i>
            </template>
            <el-menu-item index="en_es">
              <img
                class="lang"
                style="width: 20px; height: 20px; margin-right: 8px"
                src="../assets/icon/en-us.png"
              />
              <span>English</span>
            </el-menu-item>
            <el-menu-item index="es_mx">
              <img
                class="lang"
                style="width: 20px; height: 20px; margin-right: 8px"
                src="../assets/icon/es-es.png"
              />
              <span>Español</span>
            </el-menu-item>
            <!-- <el-menu-item index="zh_cn">
              <img
                class="lang"
                style="width: 20px; height: 20px; margin-right: 8px"
                src="../assets/icon/es-es.png"
              />
              <span>简体中文</span>
            </el-menu-item> -->
          </el-submenu>
        </el-col>
      </el-row>
    </el-menu>

    <el-drawer
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <div class="tool">
        <!-- logo -->
        <div class="tool-top">
          <img src="../assets/logo.png" />
        </div>
        <!-- info -->
        <div class="user-info">
          <img src="../assets/icon/avatar.png" />
          <div class="user-right">
            <span class="items"
              >{{ $t("用户名") }}：{{ info.username
              }}<img class="u-icon" src="../assets/bq.jpg"
            /></span>
            <span class="items" style="margin-top: 10px"
              >{{ $t("邀请码") }}：{{ info.invite_code }}
              <img
                class="copy"
                src="../assets/icon/copy.png"
                v-clipboard:copy="invite"
                v-clipboard:success="onSuccess"
            /></span>
          </div>
        </div>
        <div class="credible">{{ $t("可信度") }}：100%&nbsp;BT{{ info.level }}</div>
        <!-- money -->
        <div class="money">
          <div class="interest">
            <span>{{ info.yon3 ? info.yon3 : "0.00" }}</span>
            <span class="text">{{ $t("利息") }}</span>
          </div>
          <div class="deposit">
            <span>{{ info.balance }}</span>
            <span class="text">{{ $t("存款本金") }}</span>
          </div>
        </div>
        <!-- tool-item -->
        <div
          class="tool-item"
          v-for="(item, index) in list"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="item-left">
            <img v-if="!item.isLine" :src="item.icon" />
            <span>{{ item.name }}</span>
          </div>
          <i class="el-icon-arrow-right" v-if="!item.isLine"></i>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { langList } from "../api/index";
export default {
  props: {
    sendInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeIndex: "1",
      drawer: false, // 抽屉显隐
      direction: "ltr", // 抽屉方向
      list: [
        {
          icon: require("../assets/icon/1.png"),
          name: this.$t("首页"),
          path: "/",
        },
        {
          icon: require("../assets/icon/2.png"),
          name: this.$t("开采进度"),
          path: "/task",
        },
        {
          icon: require("../assets/icon/3.png"),
          name: this.$t("订单"),
          path: "/order",
        },
        {
          icon: require("../assets/icon/4.png"),
          name: this.$t("充值"),
          path: "/recharge",
        },
        {
          icon: require("../assets/icon/4.png"),
          name: this.$t("总收入"),
          path: "/total",
        },
        {
          icon: require("../assets/icon/5.png"),
          name: this.$t("质押收益"),
          path: "/pledge",
        },
        {
          icon: require("../assets/icon/6.png"),
          name: this.$t("提现"),
          path: "/withdraw",
        },
        {
          icon: require("../assets/icon/7.png"),
          name: this.$t("钱包"),
          path: "/wallet",
        },
        {
          isLine: true,
        },
        {
          isLine: true,
        },
        {
          icon: require("../assets/icon/8.png"),
          name: this.$t("证书"),
          path: "/rich",
          query: { id: 1 },
        },
        {
          icon: require("../assets/icon/9.png"),
          name: this.$t("目标和控制"),
          path: "/rich",
          query: { id: 2 },
        },
        {
          icon: require("../assets/icon/10.png"),
          name: this.$t("常见问题"),
          path: "/rich",
          query: { id: 3 },
        },
        {
          icon: require("../assets/icon/11.png"),
          name: this.$t("电子邮件"),
          path: "/rich",
          query: { id: 4 },
        },
        {
          icon: require("../assets/icon/12.png"),
          name: this.$t("等级"),
          path: "/rich",
          query: { id: 5 },
        },
        {
          icon: require("../assets/icon/13.png"),
          name: this.$t("活动"),
          path: "/rich",
          query: { id: 6 },
        },
        {
          isLine: true,
        },
        {
          isLine: true,
        },
        {
          icon: require("../assets/icon/14.png"),
          name: this.$t("安全"),
          path: "/security",
        },
        {
          icon: require("../assets/icon/15.png"),
          name: this.$t("邀请"),
          path: "/invite",
        },
        {
          icon: require("../assets/icon/16.png"),
          name: this.$t("客服"),
          path: "/service",
        },
        {
          icon: require("../assets/icon/logout.png"),
          name: this.$t("注销"),
          path: "/login",
        },
      ], // 工具列表
      showInfo: false,
      invite: "",
      info: {},
      newLang: {},
    };
  },
  watch: {
    sendInfo: {
      handler(val) {
        if (Object.keys(val).length > 0) {
          this.info = val;
        }
      },
      deep: true,
    },
  },
  computed: {
    // 映射 Vuex 的 getter 判断当前登录端
    ...mapGetters("device", ["isMobile"]),
    ...mapGetters("login", ["isLogin"]),
  },
  mounted() {
    this.info = JSON.parse(localStorage.getItem("info"))
      ? JSON.parse(localStorage.getItem("info"))
      : {};
    console.log("this.info", this.info);
    this.invite =
      "https://" +
      window.location.hostname +
      "/#/login?code=" +
      this.info.invite_code;
    if (localStorage.getItem("lang")) {
      this.newLang = {
        url:
          localStorage.getItem("lang") == "en_es"
            ? require("../assets/icon/en-us.png")
            : require("../assets/icon/es-es.png"),
        name: localStorage.getItem("lang") == "en_es" ? "English" : "Español",
      };
      console.log(this.newLang, localStorage.getItem("lang"));
    }
  },
  methods: {
    // 导航菜单点击事件
    handleSelect(key, keyPath) {
      console.log(key[0], keyPath[0]);
      if (keyPath[0] == 2) {
        this.$router.push({
          path: "/rich",
          query: { id: key[0] },
        });
        this.$emit("force", key[0]);
      } else if (keyPath[0] == 11) {
        this.drawer = true;
      } else if (keyPath[0] == 5) {
      } else if (keyPath[0] == 6) {
        localStorage.setItem("lang", key);
        this.newLang = {
          url:
            key == "en_us"
              ? require("../assets/icon/en-us.png")
              : require("../assets/icon/es-es.png"),
          name: key == "en_us" ? "English" : "Español",
        };
        this.$i18n.locale = key;
        window.location.reload();
      } else {
        this.$router.push(key);
      }
    },
    openDrawer() {
      this.drawer = true;
    },
    // 抽屉关闭回调
    handleClose(done) {
      this.drawer = false;
    },
    // 登录
    toLogin() {
      this.$emit("setLogin", true);
    },
    // 注册
    toRegister() {
      this.$emit("setRegister", true);
    },
    // 复制邀请码
    onSuccess() {
      this.$message({
        message: this.$t("复制成功"),
        type: "success",
      });
    },
    // 移动端跳转
    toDetail(item) {
      if (item.path == "/login") {
        localStorage.removeItem("token");
        this.$store.dispatch("login/checkLoginType");
        this.$router.push("/login");
      } else {
        this.$router.replace({
          path: item.path,
          query: item.query ? item.query : {},
        });
      }
    },
    // 登出
    logOut() {
      localStorage.removeItem("token");
      this.$store.dispatch("login/checkLoginType");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-bar {
  .logo {
    width: 150px;
    height: 35px;
  }

  .blogo {
    width: 100px;
    height: 25px;
  }

  .lang {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .el-menu-item {
    text-align: center;
  }

  .el-submenu {
    text-align: center;
  }

  :deep(.el-menu--horizontal > .el-menu-item.is-active) {
    border-bottom: none !important;
  }
  :deep(.el-menu--horizontal > .el-submenu.is-active .el-submenu__title) {
    border-bottom: none !important;
  }

  :deep(.el-submenu__icon-arrow) {
    display: none;
  }

  :deep(.el-drawer) {
    width: 80% !important;
  }

  // 去掉鼠标悬停效果
  :deep(.el-menu-item:hover, .el-submenu__title:hover) {
    background-color: transparent !important;
  }

  //
  :deep(.el-menu.el-menu--horizontal) {
    border-bottom: none;
  }

  // 清除点击效果
  :deep(.active-menu-item) {
    background-color: transparent !important;
  }

  .p-icon {
    width: 30px !important;
    height: 30px !important;
  }

  .tool {
    .tool-top {
      position: fixed;
      top: 16px;

      img {
        width: 170px;
        height: 40px;
      }
    }
    // 用户信息
    .user-info {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 10px;

      img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }

      .user-right {
        display: flex;
        flex-direction: column;

        .copy {
          width: 15px;
          height: 15px;
          margin-left: 5px;
        }

        .items {
          display: flex;
          align-items: center;
        }

        .u-icon {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-left: 8px;
        }
      }
    }

    .credible {
      width: 86%;
      margin: 0 auto;
      background-color: #1e4de7;
      color: #fff;
      font-size: 12px;
      padding: 5px 10px;
      border-radius: 6px;
    }

    // 利息 充值金额
    .money {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      .interest,
      .deposit {
        display: flex;
        flex-direction: column;
        text-align: center;
        background: #f6f6f6;
        width: 45%;
        border-radius: 6px;
        padding: 10px 0;

        .text {
          font-size: 12px;
          color: #999;
        }
      }
    }

    .tool-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      padding: 10px;

      .item-left {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          margin-right: 15px;
        }
      }
    }
  }

  .user-card {
    cursor: pointer;
  }
  .info-box {
    width: 350px;
    padding: 20px;
    background-color: #1e4de7;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 99;
    line-height: 2;

    .info-top {
      width: 100%;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }

      .top-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 16px;

        .invite {
          display: flex;
          align-items: center;
          color: #999;
          font-size: 14px;

          .copy {
            width: 15px;
            height: 15px;
            margin-left: 5px;
          }
        }
      }
    }

    .credible {
      margin: 5px 0 15px 0;
      background-color: #745ddd;
      color: #fff;
      font-size: 12px;
      text-align: left;
      padding: 5px 10px;
      border-radius: 6px;
    }

    .money {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .interest,
      .deposit {
        display: flex;
        flex-direction: column;
        text-align: left;
        background: #fff;
        color: #000;
        width: 45%;
        border-radius: 6px;
        padding: 10px 0;

        span {
          padding: 0 10px;
          font-size: 16px;
        }

        .text {
          font-size: 12px;
          color: #999;
        }
      }
    }

    .logout {
      width: 100%;
      border-radius: 6px;
      padding: 5px 0;
      text-align: center;
      background: #fff;
      color: #000;
      margin-top: 15px;

      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
</style>
